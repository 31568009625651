import http from '@/service/http.js';
const leilao = {
  getLeiloesAberto() {
    return new Promise((resolve, reject) => {
      http
        .get(`leilao?status=aberto`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getLeiloes() {
    return new Promise((resolve, reject) => {
      http
        .get(`leilao`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getLeilao(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`Leilao/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  getLotes(payload) {
    return new Promise((resolve, reject) => {
      http
        .get(
          `lote/painel?leilaoid=${payload.id}&status=${payload.status}&pageSize=8&pageNumber=${payload.page}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getLote(payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`Lote/${payload.id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  getLoteInfo(payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`Lote/${payload.id}/info`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default leilao;
